import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import resumeLetterButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_letter_button_click';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button from 'bloko/blocks/button';
import HoverTip, { TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';
import { EnvelopeScaleSmallKindDefault, IconColor } from 'bloko/blocks/icon';

import translation from 'src/components/translation';
import useIsClient from 'src/hooks/useIsClient';
import { UserType } from 'src/models/userType';
import { VIEW_WITHOUT_CONTACTS } from 'src/utils/constants/resumePermission';
import NEGOTIATION_LINKS from 'src/utils/employer/negotiationLinks';

const ResumeButtonSendMessage = ({ userType, isEmailEmpty, resumeId, vacancyId, link, permission, trls }) => {
    const isClient = useIsClient();

    if (userType !== UserType.Employer || !link || permission === VIEW_WITHOUT_CONTACTS || isEmailEmpty) {
        return null;
    }

    return (
        <HoverTip
            host={isClient ? document.body : null}
            layer={TipLayer.Overlay}
            render={() => trls[ResumeButtonSendMessage.trls.title]}
        >
            <Button
                onClick={() => resumeLetterButtonClick({ vacancyId, resumeId })}
                to={link.location}
                data-qa="resume__mailto-button"
                Element={Link}
                icon={<EnvelopeScaleSmallKindDefault initial={IconColor.Gray50} />}
            />
        </HoverTip>
    );
};

ResumeButtonSendMessage.propTypes = {
    trls: PropTypes.object,
    userType: PropTypes.string,
    permission: PropTypes.string,
    resumeId: PropTypes.string,
    vacancyId: PropTypes.string,
    isEmailEmpty: PropTypes.bool,
    link: PropTypes.shape({
        location: PropTypes.string,
        transitionType: PropTypes.string,
    }),
};

ResumeButtonSendMessage.trls = {
    title: 'resume.button.writeLetter',
};

export default connect((state) => ({
    userType: state.userType,
    permission: state.resume?.permission,
    isEmailEmpty: !state.resume?.email?.value,
    resumeId: state.resume?.id,
    vacancyId: state.router.location.query.vacancyId,
    link:
        state.resume?.hash &&
        state.employerResumesNegotiationLinks[state.resume?.hash] &&
        state.employerResumesNegotiationLinks[state.resume?.hash].find(
            ({ transitionType }) => transitionType === NEGOTIATION_LINKS.mail
        ),
}))(translation(ResumeButtonSendMessage));
