import { useCallback, Fragment } from 'react';
import { connect } from 'react-redux';
import { NOT_FOUND, BAD_REQUEST, CONFLICT } from 'http-status-codes';
import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';
import { format } from 'bloko/common/trl';

import Attention, { AttentionKind } from 'src/components/Attention';
import translation from 'src/components/translation';
import { UserType } from 'src/models/userType';

const ResumeError = ({ errorCode, userType, viewLimit, trls }) => {
    const renderErrorMessage = useCallback(() => {
        if (errorCode === NOT_FOUND) {
            if (userType === UserType.Anonymous) {
                return trls[ResumeError.trls.anonymousNotExists];
            }
            if (userType === UserType.Applicant || userType === UserType.NedoUser) {
                return trls[ResumeError.trls.applicantNotExists];
            }
            if (userType === UserType.Employer) {
                return trls[ResumeError.trls.employerNotExists];
            }
        }
        if (errorCode === BAD_REQUEST) {
            return trls[ResumeError.trls.badRequest];
        }

        if (errorCode === CONFLICT && userType === UserType.Employer && viewLimit) {
            return (
                <Fragment>
                    <p>{format(trls[ResumeError.trls.viewLimit], { '{0}': viewLimit })}</p>
                    <p>{trls[ResumeError.trls.viewLimitLast]}</p>
                </Fragment>
            );
        }

        return trls[ResumeError.trls.serviceError];
    }, [errorCode, userType, trls, viewLimit]);

    return (
        <Column xs="4" s="8" m="12" l="16">
            <Attention kind={AttentionKind.Bad}>{renderErrorMessage()}</Attention>
        </Column>
    );
};

ResumeError.propTypes = {
    errorCode: PropTypes.number,
    viewLimit: PropTypes.string,
    userType: PropTypes.string,
    trls: PropTypes.object,
};

ResumeError.trls = {
    badRequest: 'error.400.message',
    serviceError: 'resume.service.error',
    anonymousNotExists: 'resume.anonymous.notexists',
    applicantNotExists: 'resume.applicant.notexists',
    employerNotExists: 'resume.employer.notexists',
    viewLimit: 'resume.viewLimitExceed.content',
    viewLimitLast: 'resume.viewLimitExceed.contentLast',
};

export default connect((state) => ({
    errorCode: state.errorCode,
    userType: state.userType,
}))(translation(ResumeError));
